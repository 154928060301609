import ComponentFactory from './ComponentFactory';
import Icons from './utils/Icons';
import ScrollAnimaion from './ScrollAnimation';
import Rotation from './RotationApps';

class Main {
  constructor() {
    this.init();
  }

  init() {
    document.documentElement.classList.add('has-js');

    Icons.load();

    new ComponentFactory();
    new ScrollAnimaion();
    new Rotation();

  }
}
new Main();
