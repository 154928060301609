export default class ScrollAnimaion {
    constructor() {
        this.inti();
    }
    inti() {
        window.addEventListener("scroll", this.setScrollVar.bind(this));
        window.addEventListener("resize", this.setScrollVar.bind(this));
    }
    setScrollVar() {
        const htmlElement = document.documentElement;
        const percentOfScreeneightScrolled = htmlElement.scrollTop / htmlElement.clientHeight;
        htmlElement.style.setProperty("--scroll", Math.min (percentOfScreeneightScrolled * 100, 100));
    }
}