export default class Modal {
    constructor(element) {
        this.element = element;
        this.htmlElement = document.documentElement;
        this.close = document.querySelector('.x');
        this.modal = document.querySelector('.modal');
        this.img = this.element.querySelector('img');

        this.init();
    }
    init() {
        this.element.addEventListener('click', this.openModal.bind(this));
        this.close.addEventListener('click', this.closeModal.bind(this));

    }
    openModal() {
        const currentHeight = document.documentElement.scrollTop + "px";

        const modalSrc = this.img.src;

        this.htmlElement.classList.add('__modal');
        this.htmlElement.style.setProperty("--top", currentHeight);
        this.modal.setAttribute('src', modalSrc);
    }
    closeModal() {
        this.htmlElement.classList.remove('__modal');
        this.modal.setAttribute('src', "");
    }
}