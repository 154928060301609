export default class Header {
  constructor(element) {
    this.element = element;
    this.scrollPosition = 0;
    this.lastScrollPosittion = 0;
    this.html = document.documentElement;

    this.init();
    this.initMobile();
  }
  init() {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }
  initMobile() {
    const toggle = this.element.querySelector('.js-toggle');
    toggle.addEventListener('click', this.onToggleNav.bind(this));
  }
  onScroll(event) {
    //this.setOptions();
    this.scrollPosition = document.scrollingElement.scrollTop;
    this.headerState();
    this.setDirectionState();
  }

  headerState() {
    const scrollHeight = document.scrollingElement.scrollHeight;
    if (
      this.scrollPosition > 0) {
      this.html.classList.add('header-is-sided');
    } else {
      this.html.classList.remove('header-is-sided');
    }
  }
  setDirectionState() {
    if (this.scrollPosition >= this.lastScrollPosittion) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.add('is-scrolling-up');
      this.html.classList.remove('is-scrolling-down');
    }
  }
  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }
}
