export default class RotationApps {
    constructor() {
        this.inti();
        this.bouton = document.querySelector('#app');
        this.competences = ['Html', 'Css', 'Javascript', 'Wordpress', 'php'];
        this.apps = ['Figma', 'Visual Studio Code', 'Adobe Photoshop', 'Adobe Illustrator', 'Adobe Première Pro'];
        this.appIndex = 1;
        this.competenceIndex = 1;
    }
    inti() {
        console.log(this);
        document.querySelector('#app').addEventListener('click', this.rotateApp.bind(this));
        setInterval(this.rotateCompetence.bind(this), 3000);
    }
    rotateApp() {
        const app = document.querySelector('.app');
        app.innerHTML = this.apps[this.appIndex];
        this.appIndex += 1;

        if (this.appIndex > 4 ) {
            this.appIndex = 0;
        }
    }
    rotateCompetence() {
        const competence = document.querySelector('.competences span');
        competence.innerHTML = this.competences[this.competenceIndex];
        this.competenceIndex += 1;

        if (this.competenceIndex > 4 ) {
            this.competenceIndex = 0;
        }
    }
}